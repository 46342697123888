const wrappedContracts: Record<number, string> = {
  1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', //mainnet
  10: '0x4200000000000000000000000000000000000006', //optimism
  56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c', //bnb
  137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270', //polygon
  324: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91', //zksync
  1101: '0x4f9a0e7fd2bf6067db6994cf12e4495df938e6e9', // zkEVM
  42161: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1', //arbitrum
  43114: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7', //avalance
  42170: '0x722e8bdd2ce80a4422e880164f2079488e115365', //arbitrum nova
  59144: '0xe5d7c2a44ffddf6b295a15c148167daaaf5cf34f', //linea
  999: '0x8a5027ea12f45a13deb6CB96A07913c6e192BE84', //zoratestnet
  80002: '0x41Dc3C8eB8368bd9139Cec50434a0C294c8c1102',
  84531: '0x4200000000000000000000000000000000000006', //base goerli
  84532: '0x4200000000000000000000000000000000000006', //base goerli
  534352: '0x5300000000000000000000000000000000000004', //scroll
  7777777: '0x4200000000000000000000000000000000000006', //zora
  11155111: '0x7b79995e5f793a07bc00c21412e50ecae098e7f9', //sepolia
  28122024: '0x4200000000000000000000000000000000000006', //ancient8testnet
  68840142: '0x822b4c4713433c6b88547845850a39343bf0957e', //frametestnet
  70700: '0x77684A04145a5924eFCE0D92A7c4a2A2E8C359de', //Apex Pop
  70800: '0xBfB86801053600dd3C7FCBa6d5E85017a64cE728', //Apex Pop Testnet
  168587773: '0x4200000000000000000000000000000000000006', //blast sepolia
  81457: '0x4300000000000000000000000000000000000004', //blast
  3776: '0xE9CC37904875B459Fa5D0FE37680d36F1ED55e38', // astar zkEVM
  80084: '0x7507c1dc16935b82698e4c63f2746a2fcf994df8', // berachain testnet
  80085: '0x7507c1dc16935b82698e4c63f2746a2fcf994df8', // berachain testnet
  17069: '0x4200000000000000000000000000000000000006', // garnet
  690: '0x4200000000000000000000000000000000000006', //redstone
  666666666: '0xeb54dacb4c2ccb64f8074eceea33b5ebb38e5387', // degen
  660279: '0x3fb787101dc6be47cfe18aeee15404dcc842e6af', // xai
  1482601649: '0xab01bad2c86e24d371a13ed6367bdca819589c5d', // nebula
  7560: '0x4200000000000000000000000000000000000006', // cyber
  200901: '0xff204e2681a6fa0e2c3fade68a1b28fb90e4fc5f', // bitlayer
  1329: '0xE30feDd158A2e3b13e9badaeABaFc5516e95e8C7', // sei
  713715: '0x48a9b22b80f566e88f0f1dcc90ea15a8a3bae8a4', // sei testnet
  1993: '0x48a9b22b80f566e88f0f1dcc90ea15a8a3bae8a4', // b3 testnet
  646: '0x48a9b22b80f566e88f0f1dcc90ea15a8a3bae8a4', // flow previewnet
  70805: '0x48a9b22b80f566e88f0f1dcc90ea15a8a3bae8a4', // cloud
  13746: '0x6b885d96916d18cd78e44b42c6489ca6f8794565', // game7 testnet
  984123: '0xd5eace1274dbf70960714f513db207433615a263', // forma sketchpad
  33111: '0x8643a49363e80c7a15790703b915d1b0b6b71d56', // curtis
  11011: '0x48a9b22b80f566e88f0f1dcc90ea15a8a3bae8a4', // shape sepolia
  11124: '0x9edcde0257f2386ce177c3a7fcdd97787f0d841d', // abstract testnet
  1946: '0x4200000000000000000000000000000000000006', // minato
  29112: '0x2549584be33491340eee6762992055cda05b2581', // hychain testnet
  43210: '0xee6b04fcd07a54d78a7a23f353f2b4a0bfb4a78c', // zero testnet
  6900: '0x164906a76f1a2ea933366c446ae0ec6a37062c42', // anime testnet
  4654: '0x34AF38Ec07708dBC01C5A814fc418D3840448fce', // creator testnet
  1516: '0x1516000000000000000000000000000000000000', // story odyssey
  10143: '0x760afe86e5de5fa0ee542fc7b7b713e1c5425701', // monad testnet
}

export default wrappedContracts
